<template>
	<nav>
		<router-link :to="'/' + this.userId">Home</router-link> |
		<router-link :to="'/help' + (this.userId ? '/' + this.userId : '')">Help</router-link>
	</nav>
	<router-view />
</template>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

nav {
	padding: 30px;
}

nav a {
	font-weight: bold;
	color: #2c3e50;
}

nav a.router-link-exact-active {
	color: #42b983;
}
</style>

<script>
import {getUserId} from "./services/UserService";
export default {
	data() {
		return {
			userId: ""
		};
	},
	methods: {
		getUserId() {
			let usrId = getUserId();

			console.log("userId retrieved", usrId);
			if (usrId !== "help") {
				this.userId = usrId;
			}
		}
	},
	mounted() {
		this.getUserId();
	}
};
</script>
