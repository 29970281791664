const axios = require("axios");

export async function getUser() {
	const response = await axios.get("/api/users/" + getUserId());
	return response.data;
}

export function getUserId() {
	const url = new URL(window.location.href);
	const userId = url.pathname.replace("/", "");
	console.log("userId", userId);
	return userId;
}
