const axios = require("axios");
const {getUserId} = require("./UserService");

export async function getAllOrders() {
	const response = await axios.get("/api/orders/" + getUserId());
	// console.table(response.data);
	return response.data;
}

export async function createOrder(data) {
	let userId = getUserId();
	if (!userId) {
		return;
	}
	data.meta_data = [
		{
			key: "salesPerson",
			value: userId
		}
	];
	let response = await axios.post("/api/orders", {order: data});
	// console.log("order created", response);
	return response.data;
}

export async function getProducts() {
	// console.log("getProducts");
	const response = await axios.get("/api/products");
	// console.log("get products response", response);
	return response.data;
}
