import {createRouter, createWebHistory} from "vue-router";
import HomeView from "../views/HomeView.vue";
import HelpView from "../views/HelpView.vue";
import NoUserView from "../views/NoUserView.vue";

const routes = [
	{
		path: "/",
		name: "No User",
		component: NoUserView
	},
	{
		path: "/help",
		name: "help1",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: HelpView
	},
	{
		path: "/help/:user",
		name: "help2",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: HelpView
	},
	{
		path: "/:user",
		name: "home",
		component: HomeView
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

export default router;
