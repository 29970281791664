<template>
	<div class="container">
		<div>
			<h2 style="display: inline-block">Orders</h2>
			&nbsp;
			<pulse-loader style="display: inline-block" v-if="loading" :loading="loading"></pulse-loader>
			<button v-else @click="getAllOrders()" type="button" class="btn btn-warning">Herladen</button>
		</div>
		<br />
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>Verkocht aan</th>
					<th>Status</th>
					<th>Zakken</th>
					<th>QR</th>
				</tr>
			</thead>
			<tbody>
				<template v-for="item in orders" :key="item.id">
					<tr v-if="!qrShown">
						<td>{{ item.billing.last_name }}<br />{{ item.billing.address_1 }}</td>
						<td>{{ item.status }}</td>
						<td>{{ item.bagsSold }}</td>
						<td>
							<button @click="switchToQrView(item)" type="button" class="btn btn-success">QR</button>
						</td>
					</tr>
					<template v-if="item.id == qrItemId">
						<tr v-if="item.id == qrItemId">
							<td>{{ item.billing.last_name }}<br />{{ item.billing.address_1 }}</td>
							<td>{{ item.status }}</td>
							<td>{{ item.bagsSold }}</td>
						</tr>
						<tr v-if="item.id == qrItemId">
							<td colspan="5">
								<button @click="switchToQrView(item)" type="button" class="btn btn-danger">QR Sluiten</button>
							</td>
						</tr>
						<tr v-if="item.id == qrItemId">
							<td colspan="5">
								<qrcode-vue
									:id="item.id"
									@click="switchToQrView(item)"
									:value="item.payment_url"
									:size="qrSize"
									level="H"
								/>
							</td>
						</tr>
						<tr v-if="item.id == qrItemId">
							<td colspan="5">
								<button @click="switchToQrView(item)" type="button" class="btn btn-danger">QR Sluiten</button>
							</td>
						</tr>
					</template>
				</template>
			</tbody>
		</table>
	</div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
	name: "OrderOverview",
	props: ["orders"],
	data() {
		return {
			qrUrl: "",
			showQr: false,
			qrSize: 300,
			loading: false,
			qrShown: false,
			qrItemId: null
		};
	},
	components: {
		QrcodeVue,
		PulseLoader
	},
	watch: {
		orders: function () {
			this.loading = false;
		}
	},
	methods: {
		getAllOrders() {
			this.loading = true;
			this.$emit("getAllOrders");
		},
		switchToQrView(item) {
			if (this.qrShown) {
				this.getAllOrders();
			}
			this.qrItemId = this.qrShown ? null : item.id;
			this.qrShown = !this.qrShown;
			setTimeout(() => {
				if (this.qrShown) {
					try {
						document.getElementById(item.id).scrollIntoView();
					} catch (e) {
						// fail silently
					}
				}
			}, 1);
		}
	}
};
</script>
