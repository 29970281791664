<template>
	<div class="hello">
		<GlobalHeader />
		<div class="container mrgnbtm">
			<div class="row">
				<div class="col-md-8">
					<CreateOrder @createOrder="orderCreate($event)" />
				</div>
				<div class="col-md-4">
					<DisplayBoard :numberOfOrders="numberOfOrders" :bagsSold="bagsSold" />
				</div>
			</div>
		</div>
		<div class="row mrgnbtm">
			<OrderOverview v-if="orders.length > 0" :orders="orders" @getAllOrders="getAllOrders()" />
		</div>
	</div>
</template>

<script>
import GlobalHeader from "./GlobalHeader.vue";
import CreateOrder from "./CreateOrder.vue";
import DisplayBoard from "./DisplayBoard.vue";
import OrderOverview from "./OrderOverview.vue";
import { getAllOrders } from "../services/OrderService";

export default {
	name: "MainDashboard",
	components: {
		GlobalHeader,
		CreateOrder,
		DisplayBoard,
		OrderOverview
	},
	data() {
		return {
			orders: [],
			numberOfOrders: 0,
			bagsSold: 0
		};
	},
	methods: {
		getAllOrders() {
			getAllOrders().then((response) => {
				// console.log(response);
				this.orders = response || [];
				this.bagsSold = 0;
				this.orders.forEach((ord) => {
					this.bagsSold += ord.bagsSold;
				});
				this.numberOfOrders = this.orders.length;
			});
		},
		async orderCreate() {
			// let createResponse;
			// await createOrder(data).then((response) => {
			// 	createResponse = response;
			// });
			this.getAllOrders();
			// return createResponse;
		}
	},
	mounted() {
		this.getAllOrders();
	}
};
</script>
