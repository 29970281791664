<template>
	<div>
		<h1>Geen gebruiker</h1>
		<br />
		<p>Hi,</p>
		<p>
			Leuk dat je hier bent, maar volgens mij is er iets mis gegaan.<br />
			Als het goed is heb je een qr code of link gekregen, deze moet je volledig invullen.
		</p>
	</div>
</template>
